//定义vue全局过滤器，或全局组件，全局指令等
Vue.filter('booleanToChinese', function (value) {
    if (value && (value == true || value == 1 || value == '1')) {
        return '是'
    }else{
        return '否'
    }
})
Vue.filter('booleanToEnable', function (value) {
    if (value && (value == true || value == 1 || value == '1')) {
        return '启用'
    }else{
        return '禁用'
    }
})

//全局过滤器，转义html标签
Vue.filter('unescape', function (html) {
    return html
        .replace(html ? /&(?!#?\w+;)/g : /&/g, '&amp;')
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, "\"")
        .replace(/&#39;/g, "\'");
})

//全局过滤器转换日期格式
Vue.filter('DatetoSimple', function (value) {
    if(value){
        if (value.length>16){
            return value.substring(2,16)
        }else if (value.length<16 && value.length>=10){
            return value.substring(2,10)
        }else{
            return value
        }
    }else{
        return "";
    }

})

